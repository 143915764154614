import React, { Component } from 'react';
import EventsPage from './EventsPage';

class EventsPageContainer extends Component {
  render() {
    return <EventsPage />;
  }
}

export default EventsPageContainer;
