import React from 'react';
import NavBar from '../navigation/NavBar/NavBar';

const NotFoundPage = () => (
  <div>
    404<NavBar />
  </div>
);

export default NotFoundPage;
